import React from 'react'

function OpenSea({ width = 22, height = 22 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                fill="currentColor"
                d="M1.31 11.992l.077-.117 4.642-7.06a.16.16 0 01.278.02c.776 1.689 1.445 3.79 1.132 5.099-.134.538-.501 1.267-.914 1.94a3.51 3.51 0 01-.174.288.16.16 0 01-.132.067H1.445c-.128 0-.203-.135-.135-.237z"
            ></path>
            <path
                fill="currentColor"
                d="M22 13.304v1.117a.16.16 0 01-.099.147c-.36.15-1.59.698-2.101 1.39-1.305 1.766-2.303 4.292-4.532 4.292h-9.3C2.67 20.25 0 17.644 0 14.429v-.104c0-.085.071-.155.16-.155h5.184c.103 0 .178.093.169.19a1.68 1.68 0 00.185.969 1.83 1.83 0 001.635.987H9.9v-1.948H7.363c-.13 0-.207-.146-.132-.25l.091-.131c.24-.332.583-.847.924-1.434.233-.395.459-.818.64-1.242.037-.076.066-.155.096-.232.049-.135.1-.261.137-.388.037-.107.066-.22.095-.325.087-.36.123-.74.123-1.137a5.09 5.09 0 00-.022-.472c-.007-.17-.03-.339-.051-.508a5.128 5.128 0 00-.072-.453 7.45 7.45 0 00-.146-.677l-.02-.086c-.044-.155-.081-.303-.132-.458-.145-.486-.312-.96-.488-1.404-.064-.177-.138-.346-.211-.515-.108-.255-.218-.487-.319-.706-.051-.1-.095-.19-.14-.284-.049-.105-.1-.21-.152-.31-.036-.076-.078-.148-.108-.219l-.313-.563c-.044-.077.03-.168.115-.145l1.962.517h.006l.007.002.258.07.285.078.104.029V1.99A1 1 0 0110.908 1c.279 0 .532.11.713.29.182.18.296.427.296.701v1.683l.209.057a.169.169 0 01.047.023c.052.037.125.093.219.16.073.057.152.127.247.198.189.148.414.339.662.558.066.055.13.112.189.17.319.288.676.627 1.017 1.001.095.105.189.212.284.324.096.114.197.227.284.34.116.149.24.304.349.466.051.077.11.155.16.232.139.205.261.417.379.629.05.098.1.205.145.31.13.284.232.572.299.861.02.062.034.13.042.19v.015c.022.086.029.176.036.27a2.798 2.798 0 01-.16 1.261c-.043.121-.087.248-.144.367a4.93 4.93 0 01-.394.728 4.22 4.22 0 01-.167.261c-.064.091-.13.177-.189.26-.08.108-.167.22-.255.32-.079.105-.16.21-.247.303-.123.14-.24.274-.363.403a3.73 3.73 0 01-.233.246c-.079.085-.16.162-.233.233-.123.12-.225.212-.311.289l-.202.18a.167.167 0 01-.108.04h-1.562v1.947h1.965c.44 0 .858-.151 1.195-.43.116-.097.62-.522 1.216-1.161a.152.152 0 01.075-.045l5.429-1.526a.16.16 0 01.203.15z"
            ></path>
        </svg>
    )
}

export default OpenSea
