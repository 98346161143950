import React from 'react'

function Cog() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M22.42 9.76l-1.26-.45a1.34 1.34 0 01-.81-.77 1.38 1.38 0 010-1.11L21 6.21a2.37 2.37 0 00-3.16-3.16l-1.22.58a1.43 1.43 0 01-1.11 0 1.4 1.4 0 01-.77-.81l-.45-1.27a2.38 2.38 0 00-4.48 0l-.5 1.3a1.4 1.4 0 01-.77.81 1.43 1.43 0 01-1.11 0l-1.22-.61a2.37 2.37 0 00-3.16 3.16l.58 1.22a1.38 1.38 0 010 1.11 1.34 1.34 0 01-.81.77l-1.26.45a2.38 2.38 0 000 4.48l1.26.45a1.34 1.34 0 01.81.77 1.38 1.38 0 010 1.11l-.58 1.22A2.37 2.37 0 006.21 21l1.22-.57a1.34 1.34 0 011.11 0 1.37 1.37 0 01.77.81l.45 1.26a2.38 2.38 0 004.48 0l.45-1.26a1.37 1.37 0 01.77-.81 1.34 1.34 0 011.11 0l1.22.57A2.37 2.37 0 0021 17.79l-.58-1.22a1.38 1.38 0 010-1.11 1.34 1.34 0 01.81-.77l1.26-.45a2.38 2.38 0 000-4.48zM12 16.79a5 5 0 01-4.44-2.95 4.8 4.8 0 012.6-6.28 4.87 4.87 0 016.28 2.6 4.8 4.8 0 01-2.6 6.28 4.53 4.53 0 01-1.84.35z"
            ></path>
        </svg>
    )
}

export default Cog
