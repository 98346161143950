import React from 'react'

function ChevronDown() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="currentColor"
                d="M8 11.125a.89.89 0 01-.708-.333L3.167 6.167c-.25-.333-.209-.833.125-1.083.333-.25.791-.25 1.041.083L7.917 9.25c.041.042.083.042.166 0l3.584-4.083c.291-.333.75-.375 1.083-.083.333.291.375.75.083 1.083l-.041.042-4.084 4.625a1.087 1.087 0 01-.708.291z"
            ></path>
        </svg>
    )
}

export default ChevronDown
