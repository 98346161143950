import React from 'react'

function Instagram() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M11 2c-2.445 0-2.75.01-3.711.054-.958.044-1.611.197-2.184.419A4.387 4.387 0 003.511 3.51c-.5.5-.809 1.001-1.038 1.594-.222.573-.375 1.226-.419 2.184C2.01 8.249 2 8.555 2 11s.01 2.75.054 3.711c.044.958.197 1.611.419 2.184.229.593.537 1.095 1.038 1.594.5.5 1.001.809 1.594 1.038.573.222 1.226.375 2.184.419.96.044 1.266.054 3.711.054s2.75-.01 3.711-.054c.958-.044 1.611-.197 2.184-.419a4.387 4.387 0 001.594-1.038c.5-.5.809-1.001 1.038-1.594.222-.573.375-1.226.419-2.184.044-.96.054-1.266.054-3.711s-.01-2.75-.054-3.711c-.044-.958-.197-1.611-.419-2.184a4.387 4.387 0 00-1.038-1.594c-.5-.5-1.001-.809-1.594-1.038-.573-.222-1.226-.375-2.184-.419C13.751 2.01 13.445 2 11 2zm0 1.688c2.382 0 2.664.008 3.604.051.87.04 1.341.186 1.657.307a2.77 2.77 0 011.026.667c.311.312.505.61.667 1.026.121.316.267.787.307 1.657.043.94.052 1.222.052 3.604s-.01 2.664-.052 3.604c-.04.87-.186 1.341-.307 1.657a2.77 2.77 0 01-.668 1.026 2.77 2.77 0 01-1.026.667c-.315.121-.786.267-1.656.307-.94.043-1.222.052-3.604.052s-2.664-.01-3.604-.052c-.87-.04-1.341-.186-1.657-.307a2.77 2.77 0 01-1.026-.668 2.77 2.77 0 01-.667-1.026c-.121-.315-.267-.786-.307-1.656-.042-.94-.051-1.222-.051-3.604s.008-2.664.051-3.604c.04-.87.186-1.341.307-1.657a2.77 2.77 0 01.667-1.026 2.77 2.77 0 011.026-.667c.316-.121.787-.267 1.657-.307.94-.042 1.222-.051 3.604-.051z"
                clipRule="evenodd"
            ></path>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M11 13.813a2.812 2.812 0 110-5.625 2.812 2.812 0 010 5.624zM11 6.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9zM16.906 6.219a1.124 1.124 0 11-2.25 0 1.124 1.124 0 112.25 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Instagram
