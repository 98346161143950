import { useState } from 'react'
import { Grid, Box, Button, Flex, Link as StyledLink } from 'theme-ui'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useUserContext } from '../../context/user'
import { activeLottery } from '../../environment'

import UserDropdown from './UserDropdown'
import ConnectButton from './ConnectButton'
import MobileMenu from './MobileMenu'
import SocialIcon from './SocialIcon'
import SmallButton from './SmallButton'
import LotteryBanner from './LotteryBanner'

import Logo from '../Logo'
import Twitter from '../icons/Twitter'
import Discord from '../icons/Discord'
import OpenSea from '../icons/OpenSea'
import Instagram from '../icons/Instagram'
import RightArrow from '../icons/RightArrow'
import MenuIcon from '../icons/Menu'

export default function Header() {
    const { authenticated, avatar } = useUserContext()
    const [showMobileMenu, setShowMobileMenu] = useState(false)

    return (
        <>
            {activeLottery && <LotteryBanner />}
            {showMobileMenu && (
                <MobileMenu
                    onClickClose={() => setShowMobileMenu(false)}
                    authenticated={authenticated}
                    avatar={avatar}
                />
            )}
            <Grid
                gap={2}
                columns={[
                    'auto 1fr',
                    'auto 1fr',
                    'auto 1fr',
                    'auto 1fr',
                    '45% 10% 45%',
                ]}
                pl={[1, 1, 1, 1, 5, 6]}
                pr={[5, 5, 5, 5, 5, 6]}
                py={4}
                color="primary"
            >
                <Box
                    sx={{
                        display: ['none', 'none', 'none', 'none', 'grid'],
                        gridGap: 2,
                        gridTemplateColumns: '32px 32px 32px 32px',
                        alignItems: 'center',
                    }}
                >
                    <SocialIcon
                        ariaLabel="Twitter"
                        href="https://twitter.com/tinyfacesnft"
                    >
                        <Twitter />
                    </SocialIcon>
                    <SocialIcon
                        ariaLabel="Instagram"
                        href="http://instagram.com/tinyfacesnft"
                    >
                        <Instagram />
                    </SocialIcon>
                    <SocialIcon
                        ariaLabel="Discord"
                        href="https://discord.gg/TYTxAE99HJ"
                    >
                        <Discord />
                    </SocialIcon>
                    <SocialIcon
                        ariaLabel="OpenSea"
                        href="https://opensea.io/collection/tinyfacesofficial"
                    >
                        <OpenSea />
                    </SocialIcon>
                </Box>
                <Flex
                    sx={{
                        justifyContent: ['flex-start', 'flex-start', 'center'],
                        alignItems: 'center',
                    }}
                >
                    <StyledLink as={Link} href="/" aria-label="Home">
                        <Box sx={{ display: ['none', 'none', 'flex'] }}>
                            <Logo width={91} height={52} />
                        </Box>
                        <Box sx={{ display: ['flex', 'flex', 'none'] }}>
                            <Logo width={78} height={44} />
                        </Box>
                    </StyledLink>
                </Flex>

                <Box
                    sx={{
                        display: ['flex', 'flex', 'flex', 'none'],
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        color: 'text',
                    }}
                    onClick={() => setShowMobileMenu(true)}
                >
                    <MenuIcon />
                </Box>
                <Box
                    sx={{
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        display: ['none', 'none', 'none', 'flex'],
                    }}
                >
                    <HeaderLink href="/roadmap">Roadmap</HeaderLink>
                    <HeaderLink href="/yearbook">Yearbook</HeaderLink>
                    <HeaderLink href="/leaderboard">Leaderboard</HeaderLink>
                    <HeaderLink href="/blog">Blog</HeaderLink>

                    {authenticated ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{ width: 1, height: 24, bg: 'border' }}
                            ></Box>
                            <UserDropdown avatar={avatar} />
                        </Box>
                    ) : (
                        <ConnectButton size="small" />
                    )}

                    <Box
                        sx={{
                            ml: 2,
                            display: 'flex',
                        }}
                    >
                        <SmallButton
                            as="a"
                            target="_blank"
                            href="https://opensea.io/collection/tinyfacesofficial"
                            variant="primary"
                        >
                            Buy
                            <Box
                                sx={{
                                    display: 'inline-block',
                                }}
                                ml={2}
                            >
                                <RightArrow />
                            </Box>
                        </SmallButton>
                    </Box>
                </Box>
            </Grid>
        </>
    )
}

function HeaderLink({ children, href }) {
    const { pathname } = useRouter()
    const match = pathname == href
    return (
        <StyledLink
            as={Link}
            href={href}
            sx={{
                textDecoration: 'none',
                fontWeight: match ? 'extraBold' : 'body',
                color: match ? 'primary' : 'text',
                display: 'block',
                fontSize: [2, 3],
                mr: [2, 3],
            }}
        >
            {children}
        </StyledLink>
    )
}
