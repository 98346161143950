import { useCallback, useEffect } from 'react'
import { useAccount, useSignMessage } from 'wagmi'
import { SiweMessage } from 'siwe'
import useFetch from 'use-http'
import { ConnectButton as RainbowButton } from '@rainbow-me/rainbowkit'
import { Button, Box } from 'theme-ui'

import { useAuth } from '../../context/auth'
import { apiUrl } from '../../environment'
import SmallButton from './SmallButton'

export default function ConnectButton({ size = 'small' }) {
    const { authToken, setToken, signOut } = useAuth()

    const { signMessageAsync } = useSignMessage()

    const { get, post, response, loading, error } = useFetch(`${apiUrl}`, {
        credentials: 'include',
        headers: authToken && { Authorization: `Bearer ${authToken}` },
    })

    useEffect(() => {
        if (error) {
            signOut()
        }
    }, [error, signOut])

    const exchangeAddressForToken = useCallback(
        async ({ address, chainId }) => {
            if (loading) return

            try {
                console.log('🔑 Start exchange for token...')

                const data = await get('address/nonce')

                console.log(data)
                if (!response.ok) throw new Error('Error fetching nonce')
                console.log('✅ Nonce received...')

                const message = new SiweMessage({
                    domain: window.location.host,
                    address,
                    statement: 'Welcome to TinyFaces NFT',
                    uri: window.location.origin,
                    version: '1',
                    chainId,
                    nonce: data.nonce,
                })

                console.log('✅ Created siwe message...')

                const preparedMessage = message.prepareMessage()

                console.log('✅ Prepared message...')

                const signature = await signMessageAsync({
                    message: preparedMessage,
                })

                console.log('✅ Signature prepared...')

                // Verify signature & obtain token
                const tokenData = await post('address/token', {
                    message,
                    signature,
                })

                console.log('🔁 Exchanging token...')

                if (!response.ok) throw new Error('Error verifying message')

                console.log('🎉 Token received...')

                setToken(tokenData.token)
            } catch (error) {
                console.log('❌ Error generating token...')
                console.log(error)
            }
        },
        [response, signMessageAsync, setToken, get, loading, post]
    )

    useAccount({
        onConnect({ address, connector }) {
            exchangeAddressForToken({
                address,
                chainId: connector.chains[0].id,
            })
        },
    })

    const ButtonComponent = size === 'small' ? SmallButton : Button

    return (
        <RainbowButton.Custom>
            {({
                account,
                chain,
                openChainModal,
                openConnectModal,
                mounted,
            }) => {
                return (
                    <Box
                        sx={{
                            opacity: !mounted ? 0 : 1,
                            pointerEvents: !mounted ? 'none' : 'auto',
                            userSelect: !mounted ? 'none' : 'auto',
                            display: 'flex',
                        }}
                    >
                        {(() => {
                            if (!mounted || !account || !chain) {
                                return (
                                    <ButtonComponent
                                        sx={{ cursor: 'pointer' }}
                                        onClick={openConnectModal}
                                    >
                                        Connect Wallet
                                    </ButtonComponent>
                                )
                            }

                            if (chain.unsupported) {
                                return (
                                    <ButtonComponent
                                        sx={{ cursor: 'pointer' }}
                                        onClick={openChainModal}
                                    >
                                        Wrong network
                                    </ButtonComponent>
                                )
                            }

                            return (
                                <ButtonComponent
                                    sx={{ cursor: 'pointer' }}
                                    onClick={exchangeAddressForToken}
                                >
                                    Connect wallet
                                </ButtonComponent>
                            )
                        })()}
                    </Box>
                )
            }}
        </RainbowButton.Custom>
    )
}
