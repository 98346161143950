import React from 'react'

function Menu() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M19.13 2H.87C.39 2 0 2.373 0 2.833v.834c0 .46.39.833.87.833h18.26c.48 0 .87-.373.87-.833v-.834c0-.46-.39-.833-.87-.833zm0 6.667H.87c-.48 0-.87.373-.87.833v.834c0 .46.39.833.87.833h18.26c.48 0 .87-.373.87-.833V9.5c0-.46-.39-.833-.87-.833zM.87 15.333h18.26c.48 0 .87.373.87.833V17c0 .46-.39.833-.87.833H.87C.39 17.833 0 17.46 0 17v-.834c0-.46.39-.833.87-.833z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Menu
