import { Link as ThemeLink, Box } from 'theme-ui'
import Link from 'next/link'
import RightArrow from '../icons/RightArrow'

export default function LotteryBanner() {
    return (
        <ThemeLink
            target="_blank"
            as={Link}
            sx={{
                color: 'text',
                display: 'flex',
                textDecoration: 'none',
                bg: 'backgroundBlue',
                justifyContent: 'center',
                alignItems: 'center',
                p: 2,
                fontSize: 2,
                cursor: 'pointer',
                ':hover': {
                    bg: '#C1DBEB',
                },
            }}
            href="/lottery"
        >
            Win a rare TinyFaces NFT artwork in our lottery
            <Box sx={{ ml: 2, display: 'flex' }}>
                <RightArrow />
            </Box>
        </ThemeLink>
    )
}
