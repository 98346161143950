import { useState } from 'react'
import { Box, Avatar, Link as ThemeLink } from 'theme-ui'
import { Popover } from 'react-tiny-popover'
import Link from 'next/link'
import { useDisconnect } from 'wagmi'

import { useAuth } from '../../context/auth'
import ChevronDownIcon from '../icons/ChevronDown'
import ChevronUpIcon from '../icons/ChevronUp'
import FaceIcon from '../icons/Face'
import ExitIcon from '../icons/Exit'
import CogIcon from '../icons/Cog'
import { useUserContext } from '../../context/user'

export default function UserDropdown({ avatar }) {
    const [isOpen, setIsOpen] = useState(false)
    const { disconnect } = useDisconnect()
    const { signOut } = useAuth()
    const { tokens } = useUserContext()

    const signOutAndDisconnect = () => {
        disconnect()
        signOut()
    }

    const content = (
        <Box
            sx={{
                bg: 'backgroundBright',
                p: 2,
                borderRadius: 2,
                width: 200,
                display: 'grid',
            }}
        >
            {tokens?.length > 0 && (
                <ThemeLink
                    as={Link}
                    sx={{ textDecoration: 'none' }}
                    href="/yearbook?mine=1"
                >
                    <Item
                        icon={<FaceIcon width={14} height={14} />}
                        title="My tinies"
                    />
                </ThemeLink>
            )}

            <ThemeLink
                as={Link}
                sx={{ textDecoration: 'none' }}
                href="/settings"
            >
                <Item icon={<CogIcon />} title="Settings" />
            </ThemeLink>

            <Item
                icon={<ExitIcon />}
                title="Log out"
                onClick={signOutAndDisconnect}
            />
        </Box>
    )
    return (
        <Box
            sx={{
                color: 'text',
                display: 'flex',
                cursor: 'pointer',
            }}
            onClick={() => setIsOpen(!isOpen)}
        >
            <Popover
                isOpen={isOpen}
                positions={['bottom']}
                align="end"
                content={content}
                onClickOutside={() => setIsOpen(false)}
                padding={10}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        src={avatar}
                        sx={{ width: 36, height: 36, ml: 3, mr: 1 }}
                    />
                    {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Box>
            </Popover>
        </Box>
    )
}

function Item({ title, onClick, icon }) {
    return (
        <Box
            onClick={onClick}
            sx={{
                display: 'flex',
                borderRadius: 1,
                alignItems: 'center',
                color: 'text',
                cursor: 'pointer',
                px: 2,
                py: 2,
                ':hover': {
                    bg: 'background',
                },
            }}
        >
            <Box sx={{ width: 14, height: 14, display: 'flex' }}>{icon}</Box>
            <Box sx={{ flex: 1, fontSize: 2, pl: 2 }}>{title}</Box>
        </Box>
    )
}
