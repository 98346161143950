import React from 'react'

function Twitter({ width = 20, height = 16 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 20 16"
        >
            <path
                fill="currentColor"
                d="M20 1.894a8.294 8.294 0 01-2.357.636A4.058 4.058 0 0019.448.295a8.299 8.299 0 01-2.606.98A4.127 4.127 0 0013.847 0c-2.65 0-4.596 2.433-3.998 4.959A11.71 11.71 0 011.392.739a4.007 4.007 0 001.27 5.393 4.135 4.135 0 01-1.858-.505C.76 7.498 2.122 9.249 4.095 9.638c-.577.154-1.21.19-1.853.07.521 1.604 2.036 2.771 3.833 2.804A8.344 8.344 0 010 14.185 11.75 11.75 0 006.29 16c7.618 0 11.922-6.334 11.663-12.015A8.282 8.282 0 0020 1.895z"
            ></path>
        </svg>
    )
}

export default Twitter
