import { Button } from 'theme-ui'

export default function SmallButton({ children, as, onClick, href, ...sx }) {
    return (
        <Button
            as={as}
            sx={{
                height: ['36px', '36px', '36px'],
                lineHeight: ['36px', '36px', '36px'],
                cursor: 'pointer',
                width: '100%',
                ...sx,
            }}
            variant="primary"
            onClick={onClick}
            href={href}
        >
            {children}
        </Button>
    )
}
