import React from 'react'

function Face({ width = 20, height = 20 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path
                fill="currentColor"
                d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm2.5 7.5a1 1 0 012 0v3a1 1 0 01-2 0zm-7 0a1 1 0 012 0v3a1 1 0 01-2 0zm10 8.05a6.51 6.51 0 01-10.9 0 1 1 0 111.68-1.1 4.49 4.49 0 007.54 0 1 1 0 111.68 1.1z"
            ></path>
        </svg>
    )
}

export default Face
