import { Link } from 'theme-ui'

export default function SocialIcon({ children, href, ariaLabel }) {
    return (
        <Link
            target="_blank"
            aria-label={ariaLabel}
            sx={{
                color: 'text',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ':hover': {
                    color: 'primary',
                },
            }}
            href={href}
        >
            {children}
        </Link>
    )
}
