import { Grid, Box, Button, Flex, Link as ThemeLink } from 'theme-ui'
import { useDisconnect } from 'wagmi'
import Link from 'next/link'

import ConnectButton from './ConnectButton'
import SocialIcon from './SocialIcon'

import { useAuth } from '../../context/auth'
import { useUserContext } from '../../context/user'

import CloseIcon from '../icons/Close'
import Twitter from '../icons/Twitter'
import Discord from '../icons/Discord'
import OpenSea from '../icons/OpenSea'
import Instagram from '../icons/Instagram'
import RightArrow from '../icons/RightArrow'

export default function MobileMenu({ onClickClose, authenticated }) {
    const { disconnect } = useDisconnect()
    const { signOut } = useAuth()
    const { tokens } = useUserContext()

    const signOutAndDisconnect = () => {
        disconnect()
        signOut()
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                zIndex: 9999,
                left: 0,
                display: 'flex',
                flexDirection: 'column',
                right: 0,
                bottom: 0,
                bg: 'backgroundBright',
            }}
        >
            <Flex
                onClick={onClickClose}
                sx={{
                    width: '100%',
                    px: 5,
                    py: 6,
                    justifyContent: 'flex-end',
                    cursor: 'pointer',
                }}
            >
                <CloseIcon width={16} height={16} />
            </Flex>
            <Grid sx={{ px: 5, gridGap: 3 }}>
                <ThemeLink
                    as={Link}
                    sx={{ textDecoration: 'none' }}
                    href="/roadmap"
                >
                    <Item>Roadmap</Item>
                </ThemeLink>

                <ThemeLink
                    as={Link}
                    sx={{ textDecoration: 'none' }}
                    href="/yearbook"
                >
                    <Item>Yearbook</Item>
                </ThemeLink>

                <ThemeLink
                    as={Link}
                    sx={{ textDecoration: 'none' }}
                    href="/leaderboard"
                >
                    <Item>Leaderboard</Item>
                </ThemeLink>

                <ThemeLink
                    as={Link}
                    sx={{ textDecoration: 'none' }}
                    href="/blog"
                >
                    <Item>Blog</Item>
                </ThemeLink>

                <Box sx={{ height: 1, bg: 'border', my: 5 }}></Box>
            </Grid>
            <Box sx={{ flex: 1, pt: 3 }}>
                <Box>
                    {authenticated && (
                        <Grid sx={{ gridGap: 3, px: 5 }}>
                            {tokens?.length > 0 && (
                                <ThemeLink
                                    as={Link}
                                    sx={{ textDecoration: 'none' }}
                                    href="/yearbook?mine=1"
                                >
                                    <Item>My tinies</Item>
                                </ThemeLink>
                            )}

                            <ThemeLink
                                as={Link}
                                sx={{ textDecoration: 'none' }}
                                href="/settings"
                            >
                                <Item>Settings</Item>
                            </ThemeLink>

                            <Item onClick={signOutAndDisconnect}>Log out</Item>
                            <Box sx={{ height: 1, bg: 'border', my: 5 }}></Box>
                        </Grid>
                    )}

                    <Box sx={{ px: 5, display: 'grid', gridGap: 3 }}>
                        {!authenticated && <ConnectButton />}
                        <Button
                            as="a"
                            target="_blank"
                            sx={{
                                height: ['36px', '36px', '36px'],
                                lineHeight: ['36px', '36px', '36px'],
                            }}
                            href="https://opensea.io/collection/tinyfacesofficial"
                            variant="primary"
                        >
                            Buy
                            <Box
                                sx={{
                                    display: 'inline-block',
                                }}
                                ml={2}
                            >
                                <RightArrow />
                            </Box>
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Grid
                sx={{
                    py: 5,
                    pl: 3,
                    gridTemplateColumns: 'repeat(auto-fit, 50px)',
                    gridGap: 1,
                }}
            >
                <SocialIcon href="https://twitter.com/tinyfacesnft">
                    <Twitter />
                </SocialIcon>
                <SocialIcon href="http://instagram.com/tinyfacesnft">
                    <Instagram />
                </SocialIcon>
                <SocialIcon href="https://discord.gg/TYTxAE99HJ">
                    <Discord />
                </SocialIcon>
                <SocialIcon href="https://opensea.io/collection/tinyfacesofficial">
                    <OpenSea />
                </SocialIcon>
            </Grid>
        </Box>
    )
}

function Item({ children }) {
    return (
        <Box
            sx={{
                display: 'flex',
                color: 'text',
                alignItems: 'center',
                textDecoration: 'none',
                fontWeight: 'body',
                cursor: 'pointer',
            }}
        >
            <Box sx={{ flex: 1, fontSize: 4, pl: 2 }}>{children}</Box>
        </Box>
    )
}
