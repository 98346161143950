import React from 'react'

function Exit() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                fill="currentColor"
                d="M20.25 1.5h-7.5v-1a.51.51 0 00-.19-.39.48.48 0 00-.42-.1l-9 2a.5.5 0 00-.39.49v19a.5.5 0 00.39.49l9 2a.52.52 0 00.42-.1.49.49 0 00.19-.39v-1h7.5a1 1 0 001-1v-19a1 1 0 00-1-1zm-10 11a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5zm8.5 8h-5.5a.5.5 0 01-.5-.5V4a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5v16a.5.5 0 01-.5.5z"
                transform="scale(.83333)"
            ></path>
        </svg>
    )
}

export default Exit
