import React from 'react'

function Close({ width = 8, height = 8 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 8 8"
        >
            <g>
                <path
                    fill="currentColor"
                    d="M4.767 4.06a.08.08 0 010-.117L7.853.853A.497.497 0 007.5.002a.503.503 0 00-.353.145L4.06 3.233a.083.083 0 01-.12 0L.853.147A.503.503 0 000 .5a.497.497 0 00.148.353l3.086 3.09a.08.08 0 010 .117L.147 7.147a.497.497 0 00.353.851c.132 0 .26-.052.353-.145L3.94 4.767a.083.083 0 01.12 0l3.087 3.086A.503.503 0 008 7.5a.497.497 0 00-.148-.353L4.767 4.06z"
                ></path>
            </g>
        </svg>
    )
}

export default Close
