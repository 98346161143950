import React from 'react'

function ChevronUp() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="currentColor"
                d="M8 4.875a.89.89 0 00-.708.333L3.167 9.833c-.25.333-.209.833.125 1.083.333.25.791.25 1.041-.083L7.917 6.75c.041-.042.083-.042.166 0l3.584 4.083c.291.333.75.375 1.083.083.333-.291.375-.75.083-1.083l-.041-.042-4.084-4.625A1.087 1.087 0 008 4.875z"
            ></path>
        </svg>
    )
}

export default ChevronUp
