import React from 'react'

function Discord({ width = 22, height = 22 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                fill="currentColor"
                d="M18.636 3.859A17.632 17.632 0 0014.097 2.4c-.195.362-.424.848-.581 1.235a16.3 16.3 0 00-5.03 0A13.324 13.324 0 007.897 2.4a17.574 17.574 0 00-4.542 1.463C.483 8.302-.295 12.63.093 16.898 2 18.353 3.845 19.237 5.66 19.815c.448-.63.848-1.301 1.192-2.008a11.62 11.62 0 01-1.877-.934c.157-.12.311-.244.46-.373 3.62 1.732 7.553 1.732 11.13 0 .15.129.304.253.46.373-.595.367-1.225.681-1.88.936.344.705.742 1.377 1.191 2.008 1.817-.578 3.665-1.462 5.57-2.919.457-4.947-.78-9.236-3.27-13.039zM7.346 14.274c-1.087 0-1.978-1.038-1.978-2.301 0-1.264.872-2.303 1.977-2.303 1.106 0 1.997 1.037 1.978 2.303.002 1.263-.872 2.3-1.978 2.3zm7.309 0c-1.087 0-1.978-1.038-1.978-2.301 0-1.264.872-2.303 1.978-2.303 1.105 0 1.996 1.037 1.977 2.303 0 1.263-.872 2.3-1.978 2.3z"
            ></path>
        </svg>
    )
}

export default Discord
